<template>
  <div class="about_section">
    <Banner :data="bannerData" />
    <ImageSide :data="imageSide" />
    <Texting :data="textData" />
    <ImageSide :data="imageSide1" />
    <TextDouble :data="doubleText" />
    <Team v-if="miembros" :miembros="miembros" />
    <Contacto />
  </div>
</template>

<script>
import Banner from "@/components/shared/Banner.vue";
import ImageSide from "@/components/shared/ImageSide.vue";
import Texting from "@/components/shared/Text.vue";
import TextDouble from "@/components/shared/TextDouble.vue";
import Team from "@/components/shared/Team.vue";
import Contacto from "@/components/shared/Contacto.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Banner,
    ImageSide,
    Texting,
    TextDouble,
    Team,
    Contacto,
  },
  data() {
    return {
      
    };
  },
  methods: {
    ...mapActions({
      getMiembros: "equipo/getMiembros",
    }),
  },
  computed: {
    ...mapGetters({
      miembros: "equipo/miembros",
    }),
    bannerData() {
      return{
        bg: "img/CDMX-3.jpg",
        title: this.$t('us.banner.title'),
        scrollDown: true,
        scrollText: this.$t('us.banner.scrollT'),
      }
    },
    imageSide() {
      return{
        lineUp: false,
        upTitle: "",
        lineTitle: true,
        title: this.$t('home.somos.title'),
        textLeft: this.$t('home.somos.textLeft'),
        imgRight: "img/somos-right.jpg",
        position: "up",
      }
    },
    textData() {
      return{
        lineUp: true,
        upTitle: this.$t('us.history.title'),
        title: this.$t('us.history.text'),
        lBottom: false,
      }
    },
    imageSide1() {
      return{
        lineUp: false,
        upTitle: "",
        lineTitle: false,
        title: "",
        textLeft: this.$t('us.integration.text'),
        inverted: true,
        fullRow: true,
        imgRight: "img/integracion-sinergia.jpg",
        position: "",
      }
    },
    doubleText() {
      return{
        text1: this.$t('us.double.text1'),
        text2: this.$t('us.double.text2'),
      }
    },
    teamData(){
      var teamDatas = Array();
      teamDatas.push(
      {
        img: "equipo/equipo.jpg",
        name: "FRANCIS PONS.",
        title: "Presidente Akko Group Miami",
        description:
          "Agustín inició su carrera inmobiliaria en 1998 como Director de Desarrollo en SARE Group Inmobiliario.",
        link: "/equipo",
      });
      return teamDatas;
    },
  },

  async mounted() {
    await this.getMiembros();
  },
};
</script>

<style scoped></style>
