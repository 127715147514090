<template>
    <div class="textsD_upper">
        <b-container class="textsD_container">
            <b-row class="textsD_wrap">
                <b-col lg="6" md="6" sm="12" class="textD_upper">
                    <p>{{data.text1}}</p>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                    <p>{{data.text2}}</p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    props:{
        data: Object,
    }
}
</script>

<style scoped>
.textsD_upper{
    padding: 34px 0 54px;
}
.textsD_container .textsD_wrap{
    position: relative;
}
.textsD_container .textsD_wrap:after{
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -15px;
    width: 35vw;
    height: 3px;
    background: var(--primario);
}
.textsD_container .textsD_wrap p{
    font-size: 21px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.33;
    text-align: center;
    color: var(--texto);
}
@media(max-width: 576px){
    .textsD_container .textsD_wrap:after{
        width: 80%;
    }
}
</style>