<template>
  <div class="teams_upper">
    <b-container class="teams_container">
      <b-row class="teams_wrap" align-v="center">
        <b-col class="team_wrap">
          <h2 v-html="this.$t('team.title')"></h2>
          <h3 class="line-before">{{this.$t('team.main')}}</h3>
        </b-col>
      </b-row>
      <b-row class="teams_wrap teams_normal" align-v="center">
        <b-col
          v-for="(miembro, index) in miembros"
          :key="index"
          lg="3"
          md="6"
          class="team_wrap"
        >
          <div class="team_content">
            <div class="team_img">
              <b-img
                v-if="miembro.imagen"
                :src="miembro.imagen.url"
                fluid
                alt="Responsive image"
              ></b-img>
            </div>
            <div class="team_detail">
              <h5 v-html="miembro.nombre"></h5>
              <h6 v-html="localeCode==='es' ? miembro.cargo : miembro.cargoIngles" />
              <p v-html="localeCode==='es' ? miembro.descripcion_resumida : miembro.descripcion_resumidaIngles "></p>
              <b-link @click="router.push('equipo/' + miembro.id)"
                >{{$t('common.read_more')}}
                <b-img :src="require('@/assets/img/svg/ico-plus.svg')"></b-img
              ></b-link>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="teams_wrap teams_small" align-v="center">
        <b-carousel id="carousel-2" :interval="4000" controls img-height="480">
          <b-carousel-slide
            v-for="(miembro, index) in miembros"
            :key="index"
            img-height="1580"
            caption="Blank Image"
            img-blank
            img-alt="Blank image"
          >
            <div class="team_content">
              <div class="team_img">
                <b-img :src="miembro.img" fluid alt="Responsive image"></b-img>
              </div>
              <div class="team_detail">
                <h5 v-html="miembro.name"></h5>
                <h6 v-html="miembro.title"></h6>
                <p v-html="miembro.description"></p>
                <b-link v-if="miembro.link" @click="router.push('equipo')"
                  >{{$t('common.read_more')}}
                  <b-img :src="require('@/assets/img/svg/ico-plus.svg')"></b-img
                ></b-link>
              </div>
            </div>
          </b-carousel-slide>
        </b-carousel>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import router from "../../router";
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      router,
    };
  },
  props: {
    miembros: Array,
  },
  computed:{
    ...mapGetters({
      localeCode: "locale/localeCode",
    })
  }
};
</script>

<style scoped>
.teams_upper {
  background-image: url("~@/assets/img/bg-rectangle.png");
  background-size: 75.5vw;
  background-position: right 110px;
  background-repeat: no-repeat;
}
.teams_container .teams_wrap h2 {
  font-size: 34px;
  font-weight: normal;
  line-height: 1.18;
  text-align: center;
  color: var(--texto);
}
.teams_container .teams_wrap h2 >>> b {
  font-size: 45px;
  font-weight: 900;
}
.teams_container .teams_wrap h3 {
  font-size: 30px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -0.12px;
  color: var(--texto);
}
.teams_container .teams_wrap .line-before:before {
  content: "";
  width: 60px;
  height: 2.6px;
  left: 0;
  top: 0;
  display: inline-block;
  background: var(--primario);
  margin-right: 8.4px;
  margin-bottom: 8px;
}

.teams_container .teams_wrap .team_content {
  margin-bottom: 40px;
  position: relative;
}
.teams_container
  .teams_wrap
  .team_wrap:not(:nth-child(4n))
  .team_content:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 1px;
  height: 100%;
  border: 1px dashed var(--texto);
}
.teams_container .teams_wrap .team_content:nth-child(4n):after {
  content: none;
}
.teams_container .teams_wrap .team_content .team_img {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}
.teams_container .teams_wrap .team_content .team_img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.teams_container .teams_wrap .team_content .team_detail h5 {
  font-size: 16.9px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--texto);
}
.teams_container .teams_wrap .team_content .team_detail h6 {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 7px;
}
.teams_container .teams_wrap .team_content .team_detail p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.07px;
  text-align: center;
  margin-bottom: 5px;
  color: var(--texto);
}
.teams_container .teams_wrap .team_content .team_detail a {
  font-size: 13.9px;
  font-style: italic;
  line-height: 1.2;
  text-align: left;
  color: var(--primario);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.teams_container .teams_wrap .team_content .team_detail a img {
  margin-left: 5px;
}

.teams_normal {
  display: flex;
}
.teams_small {
  display: none;
}
@media (max-width: 992px) {
  .teams_upper {
    background-image: url("~@/assets/img/bg-v-rectangle.png");
    background-size: 50vw;
  }
  .teams_container .teams_wrap h2 {
    font-size: 28px;
  }
  .teams_container .teams_wrap h2 >>> b {
    font-size: 35px;
  }
  .teams_container
    .teams_wrap
    .team_wrap:not(:nth-child(even))
    .team_content:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    width: 1px;
    height: 100%;
    border: 1px dashed var(--primario);
  }
  .teams_container .teams_wrap .team_wrap:nth-child(even) .team_content:after {
    content: none;
  }
}
@media (max-width: 576px) {
  .teams_upper {
    background-size: 30vw;
  }
  .teams_container .teams_wrap h2 {
    font-size: 15px;
  }
  .teams_container .teams_wrap h2 >>> b {
    font-size: 19px;
  }
  .teams_container .teams_wrap .carousel-item > img {
    height: 450px;
  }
  .teams_normal {
    display: none;
  }
  .teams_small {
    display: block;
  }
  .teams_small >>> .carousel .carousel-control-prev-icon {
    background-image: url("~@/assets/img/ico-left-color.png");
    background-size: contain;
  }
  .teams_small >>> .carousel .carousel-control-next-icon {
    background-image: url("~@/assets/img/ico-right-color.png");
    background-size: contain;
  }
}
</style>
