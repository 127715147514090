<template>
  <div
    class="contacts_upper overlay"
    :style="{
      backgroundImage: `url(${require('@/assets/img/ContactBg.jpg')})`,
    }"
  >
    <b-container class="contacts_container">
      <div class="header-above"><h3>{{this.$t('contact.title')}}</h3></div>
      <b-row class="contacts_wrap justify-content-lg-between" align-v="stretch" align-h="center">
        <b-col lg="3" md="6" cols="8" class="contact_wrap">
          <div>
            <p class="title-contact">{{this.$t('contact.title')}}</p>
            <b-link href="https://api.whatsapp.com/send?phone=+525529761089" target="_blank">
              <b-img
                class="d-block mx-auto"
                :src="require('@/assets/img/svg/ico-phone.svg')"
                fluid
                alt="Responsive image"
              ></b-img>
            </b-link>
            <ul class="list-contact">
              <li>
                <b-link href="tel:+525529761089"><b>+52</b> (55) 29.76.10.89</b-link>
              </li>
              <li>
                <b-link href="tel:+525529760217"><b>+52</b> (55) 29.76.02.17</b-link>
              </li>
              <li>
                <b-link href="tel:+525555733670"><b>+52</b> (55) 55.73.36.70</b-link>
              </li>
              <li class="vacio"></li>
              <li>
                <b-link href="mailto:agustin.alvarado@akko-group.com"><b>agustin.alvarado@akko-group.com</b></b-link>
              </li>
              <li>
                <b-link href="mailto:rogelio.lagunes@akko-group.com"><b>rogelio.lagunes@akko-group.com</b></b-link>
              </li>
              <li>
                <b-link href="mauricio.mendoza@akko-group.com"><b>mauricio.mendoza@akko-group.com</b></b-link>
              </li>
              <li>
                <b-link href="diego.sandoval@akko-group.com"><b>diego.sandoval@akko-group.com</b></b-link>
              </li>
              <li>
                <b-link href="pedro.tapia@akko-group.com"><b>pedro.tapia@akko-group.com</b></b-link>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col lg="4" md="12" class="contact_wrap">
          <b-form @submit.prevent="sendComment()">
            <b-form-group id="input-group-1" :label="this.$t('contact.form.name')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="form.nombre"
                type="text"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" :label="this.$t('contact.form.email')" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.correo"
                type="email"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              :label="this.$t('contact.form.phone')"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="form.telefono"
                type="tel"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-4"
              :label="this.$t('contact.form.message')"
              label-for="textarea"
            >
              <b-form-textarea
                id="textarea"
                v-model="form.mensaje"
                placeholder=""
                rows="4"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-button type="submit" variant="primary" class="transparent">
              <b-spinner
                v-if="loading"
                variant="primary"
                label="Spinning"
              ></b-spinner
              ><span v-else>{{this.$t('contact.form.btn')}}</span>
            </b-button>
          </b-form>
        </b-col>
        <b-col lg="3" md="6" cols="8" class="contact_wrap flex">
          <div>
            <p class="title-contact">{{this.$t('contact.miami.title')}}</p>
            <b-link href="https://api.whatsapp.com/send?phone=+525529761089" target="_blank">
              <b-img
                class="d-block mx-auto"
                :src="require('@/assets/img/svg/ico-phone.svg')"
                fluid
                alt="Responsive image"
              ></b-img>
            </b-link>
            <ul class="list-contact">
              <li>
                <b-link href="tel:+0013055331212">001 305 533 1212</b-link>
              </li>
              <li>
                <b-link href="tel:+0013055331211">001 305 533 1211</b-link>
              </li>
              <li class="vacio"></li>
              <li>
                <b-link href="mailto:francis.pons@akko-group.com"><b>francis.pons@akko-group.com</b></b-link>
              </li>
              <li>
                <b-link href="mailto:janell.barquin@akko-group.com"><b>janell.barquin@akko-group.com</b></b-link>
              </li>
              <li>
                <b-link href="mailto:jose.monros@akko-group.com"><b>jose.monros@akko-group.com</b></b-link>
              </li>
              <li>
                <b-link href="mailto:mike.mejia@akko-group-com"><b>mike.mejia@akko-group-com</b></b-link>
              </li>
              <li>
                <b-link href="mailto:sergio.freyer@akko-group.com"><b>sergio.freyer@akko-group.com</b></b-link>
              </li>
            </ul>
          </div>
          <b-img
            class="logo-contact d-none d-lg-block"
            :src="require('@/assets/img/Logo2.png')"
            fluid
            alt="Responsive image"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import {sendEmail} from '@/helpers/email.js'

export default {
  data() {
    return {
      form: {
        nombre: "",
        correo: "",
        telefono: "",
        mensaje: "",
      },
      loading: false,
    };
  },
  computed:{
    ...mapGetters({
      localeCode: "locale/localeCode",
    }),
  },
  methods: {
    async sendComment() {

      this.loading = true;
      
      try {

        if (this.form.mensaje.length === 0) return this.showEmptyMessage()

        const data = {
          ...this.form,
          asunto:this.localeCode==='es' ? 'Contacto Akko' : 'Concat Form'
        }

        await sendEmail(data,this.localeCode)

        this.clearForm()
        this.showMessageSent()
        
      } catch (error) {
        
        console.log(error);
        this.showErrorSendMessage()

      } finally {
        
        this.loading = false;

      }
      
    },
    showEmptyMessage(){
      this.$swal("Error", "Por favor, escribe un mensaje", "danger");
    },
    showErrorSendMessage(){
      this.$swal("Error", "Error al enviar el mensaje", "danger");
    },
    showMessageSent(){
      this.$swal("Bien hecho", "El mensaje ha sido enviado correctamente", "success");
    },
    clearForm(){
      this.form.nombre = "";
      this.form.correo = "";
      this.form.telefono = "";
      this.form.mensaje = "";
    },
  },
};
</script>

<style scoped>
.contacts_upper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 60px;
}
.contacts_container .header-above {
  position: absolute;
  top: -40px;
  left: 0;
}
.contacts_container h3 {
  font-size: 19px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -0.08px;
  color: #686058;
}
.contacts_container h3:before {
  content: "";
  width: 60px;
  height: 2.6px;
  display: inline-block;
  background: var(--primario);
  margin-right: 8.4px;
  margin-bottom: 4px;
}
.contacts_container {
  padding-top: 100px;
  padding-bottom: 78px;
  position: relative;
}
.contacts_container .contact_wrap.flex {
  display: flex;
  flex-direction: column;
}
.contacts_container .contact_wrap.flex .logo-contact {
  margin-top: auto;
}
.contacts_container .contact_wrap img {
  margin-top: 18px;
  margin-bottom: 18px;
}
.contacts_container .contact_wrap .title-contact {
  font-size: 20px;
  font-style: italic;
  line-height: 1;
  text-align: center;
  color: var(--blanco);
  margin-bottom: 0;
}
.contacts_container .contact_wrap .list-contact {
  list-style: none;
  padding-left: 0;
}
.contacts_container .contact_wrap .list-contact li {
  text-align: center;
}
.contacts_container .contact_wrap .list-contact li.vacio {
  min-height: 20px;
}
.contacts_container .contact_wrap .list-contact li a {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--blanco);
}
.contacts_container .contact_wrap .form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 38px;
}
.contacts_container .contact_wrap .form-group >>> label {
  width: 20%;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: var(--blanco);
}
.contacts_container .contact_wrap .form-group >>> label + div {
  width: 75%;
}
.contacts_container .contact_wrap .form-group >>> textarea,
.contacts_container .contact_wrap .form-group >>> input.form-control {
  padding: 0 10px;
}
.contacts_container .contact_wrap .form-group >>> input.form-control {
  height: 22px;
}
.contacts_container .contact_wrap [type="submit"] {
  display: block;
  margin-left: auto;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.71;
  text-align: center;
}
@media(max-width: 992px){
  .contacts_container .contact_wrap:first-child,
  .contacts_container .contact_wrap:nth-child(3){
    order: -1;
  }
  .contacts_container .contact_wrap .form-group >>> label{
    text-align: left;
  }
}
@media(max-width: 576px){
  .contacts_container .header-above{
    left: 30px;
  }
  .contacts_container .contact_wrap .title-contact{
    font-size: 23px;
  }
  .contacts_container .contact_wrap .form-group{
    flex-direction: column;
    align-content: center;
  }
  .contacts_container .contact_wrap .form-group >>> label{
    margin-bottom: 0;
  }
}
</style>
